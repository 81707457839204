import { computed, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MENU, IOfficeMenu } from '@libs/helper/office/office-menu.helper';
import { ENUM_EMPLOYEE_GROUP } from '@libs/interfaces';
import { OfficeStoreSelectService } from '../store/office-store-select.service';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmployeeRoleService {
  employeeGroup = computed<ENUM_EMPLOYEE_GROUP>(() => this.OfficeStoreSelectService.employeeGroup());
  constructor(private OfficeStoreSelectService: OfficeStoreSelectService) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const item = MENU.find((item: IOfficeMenu) => {
      return state.url.indexOf(item.link) == 1;
    });
    if (!item) return true;
    return of(this.employeeGroup() <= item.group);
  }
}
